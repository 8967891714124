export const getCommasCount = (str: string, caretIndex: number) => {
  let count = 0

  for (let i = 0; i < caretIndex + count; i++) {
    if (str[str.length - i - 1] === ',') {
      count++
    }
  }
  return count
}
