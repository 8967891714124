import { EventHandler } from 'react'

/**
 *
 * Reassigns the event target to the current target: it is used to fix issues with currentTarget being null in
 * some libraries (i.e. react-number-format) that won't work otherwise with react-aria focus handlers
 * @param event Any synthetic event
 * @param cb A callback to be fired once the event is reassigned
 */
export const reassignEventTargetToCurrentTarget = (
  event: React.SyntheticEvent,
  cb?: EventHandler<any>
) => {
  event.currentTarget = event.target as any
  cb?.(event)
}
