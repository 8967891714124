import { ChangeEvent, useEffect, useRef } from 'react'
import { usePrevious } from './usePrevious'

interface CompositionEndValueSyncProps {
  value?: string | number | readonly string[] | undefined
  isComposing?: boolean
  id?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
}

/**
 * When selecting from the IME candidate list on mac, the value will not update after the compositionend event,
 * but before so our usual onChange events will not be triggered. So we need to manually trigger the onChange event.
 */
export const useCompositionEndValueSync = ({
  isComposing,
  id,
  value,
  onChange,
  name,
}: CompositionEndValueSyncProps) => {
  const previousIsComposing = usePrevious(isComposing)
  const composingFormattedValueRef = useRef<string | null>(null)

  useEffect(() => {
    if (
      !isComposing &&
      previousIsComposing &&
      composingFormattedValueRef.current &&
      value !== composingFormattedValueRef.current
    ) {
      onChange({
        target: {
          value: composingFormattedValueRef.current,
          id: id ?? '',
          name: name ?? '',
        },
      } as ChangeEvent<HTMLInputElement>)
    }
  }, [id, isComposing, name, onChange, previousIsComposing, value])

  return composingFormattedValueRef
}
