export const getDiffIndexes = (a: string, b: string) => {
  const diffIndexes: number[] = []
  for (let i = 0; i < a.length; i++) {
    if (a.charCodeAt(i) !== b.charCodeAt(i)) {
      diffIndexes.push(i)
      break
    }
  }
  for (let i = 1; i <= a.length; i++) {
    if (a.charCodeAt(a.length - i) !== b.charCodeAt(b.length - i)) {
      diffIndexes.push(i - 1)
      break
    }
  }
  return diffIndexes
}
