import { useCompositionEndValueSync } from 'hooks'
import { ChangeEvent, forwardRef, useCallback } from 'react'
import { useObjectRef } from 'react-aria'
import { NumberFormatBase, usePatternFormat } from 'react-number-format'
import { reassignEventTargetToCurrentTarget } from '../../../utils'
import { TextInputBoxProps } from '../types'
import { getTextUntilFirstWhitespace } from '../utils/get-text-until-first-whitespace'
import { normalizeNumericString } from '../utils/normalize-numeric-string'

export type InputVariant = 'input' | 'input-error'

export type PatternInputProps = Omit<TextInputBoxProps, 'thousandSeparator'> & {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  pattern?: string
}

export const PatternInput = forwardRef<HTMLInputElement, PatternInputProps>(
  (
    {
      id,
      value,
      onChange,
      onPaste,
      inputClassName,
      autoComplete = 'off',
      isComposing,
      pattern = '',
      style,
      ...props
    },
    ref
  ) => {
    const inputRef = useObjectRef(ref)
    const composingFormattedValueRef = useCompositionEndValueSync({
      isComposing,
      id,
      value,
      onChange,
      name: props.name,
    })
    const { format, ...rest } = usePatternFormat({
      ...props,
      format: pattern,
      type: 'text',
      defaultValue: props.defaultValue?.toString() ?? undefined,
    })

    const compositionValueFormatter = useCallback(
      // DO NOT CHANGE THIS METHOD: THIS PLACE IS THE SOURCE OF ANY POSSIBLE OS COMPOSITION BUG

      (val: string) => {
        if (!isComposing) {
          return getTextUntilFirstWhitespace(
            format?.(
              normalizeNumericString(val, {
                ignoreMinusSign: true,
                ignoreDecimalFormatting: true,
                preserveInitialZeros: true,
              })
            ) ?? ''
          )
        }

        return val
      },
      [isComposing, format]
    )
    const handleValueChange = useCallback(
      ({ formattedValue }: { formattedValue: string }) => {
        if (!isComposing) {
          composingFormattedValueRef.current = null
          onChange({
            target: {
              value: formattedValue,
              id: id ?? '',
              name: props.name ?? '',
            },
          } as ChangeEvent<HTMLInputElement>)
        } else {
          composingFormattedValueRef.current = formattedValue
        }
      },
      [id, isComposing, onChange, props.name, composingFormattedValueRef]
    )

    return (
      <NumberFormatBase
        {...props}
        {...rest}
        className={inputClassName}
        autoComplete={autoComplete}
        defaultValue={props.defaultValue?.toString() ?? undefined}
        id={id}
        onPaste={onPaste}
        style={style}
        format={compositionValueFormatter}
        onValueChange={handleValueChange}
        removeFormatting={(val) => val}
        type="text"
        value={value?.toString() ?? undefined}
        getInputRef={inputRef}
        valueIsNumericString
        onFocus={(e) => reassignEventTargetToCurrentTarget(e, props.onFocus)}
        onBlur={(e) => reassignEventTargetToCurrentTarget(e, props.onBlur)}
      />
    )
  }
)

PatternInput.displayName = 'PatternInput'
