import clsx from 'clsx'
import { ChangeEventHandler, forwardRef } from 'react'
import { isNotNil } from 'typeguards'
import { getPreventEnterDefault } from '../../utils'
import { adornmentCx, inputBoxCx, inputCx } from './TextInputBox.css'
import { FormattedAlphanumericInput } from './formatted-inputs/FormattedAlphanumericInput'
import { FormattedNumberInput } from './formatted-inputs/FormattedNumberInput'
import { PatternInput } from './formatted-inputs/PatternInput'
import { TextInputBoxProps } from './types'
import { useCompositionHandlersProps } from './utils/useCompositionHandlers'

export const TextInputBox = forwardRef<HTMLInputElement, TextInputBoxProps>(
  (
    {
      adornment,
      autoComplete = 'off',
      autoFocus,
      fontSize = 'md',
      id,
      onChange,
      preventEnterDefault,
      thousandSeparator,
      value,
      isDisabled,
      isReadOnly,
      ...props
    },
    ref
  ) => {
    const { isComposing, ...restProps } = useCompositionHandlersProps<TextInputBoxProps>(props)

    const handleKeyPressBehavior = getPreventEnterDefault(
      preventEnterDefault || isComposing,
      props.onKeyPress
    )

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => onChange?.(e)

    const coreProps = {
      ...restProps,
      autoFocus,
      disabled: isDisabled,
      readOnly: isReadOnly,
      className: clsx([inputBoxCx({ fontSize }), props.className]),
      autoComplete,
      id,
      onChange: handleChange,
      onKeyPress: handleKeyPressBehavior,
      value,
    }

    const formattedInputBaseProps = {
      ...coreProps,
      defaultValue: props.defaultValue,
      isComposing,
    }

    return (
      <>
        <div className={inputCx}>
          {props.type === 'pattern' ? (
            <PatternInput {...formattedInputBaseProps} pattern={props.pattern} ref={ref} />
          ) : props.type === 'alphanumeric' ? (
            <FormattedAlphanumericInput {...formattedInputBaseProps} ref={ref} />
          ) : props.type === 'number' ? (
            <FormattedNumberInput
              {...formattedInputBaseProps}
              thousandSeparator={thousandSeparator}
              ref={ref}
            />
          ) : (
            <input {...coreProps} ref={ref} />
          )}
        </div>
        {isNotNil(adornment) && <div className={adornmentCx}>{adornment}</div>}
      </>
    )
  }
)

TextInputBox.displayName = 'TextInput'
