import { useCompositionEndValueSync } from 'hooks'
import { ChangeEvent, forwardRef, useCallback } from 'react'
import { useObjectRef } from 'react-aria'
import { NumberFormatBase, useNumericFormat } from 'react-number-format'
import { reassignEventTargetToCurrentTarget } from '../../../utils'
import { TextInputBoxProps } from '../types'
import { getTextUntilFirstWhitespace } from '../utils/get-text-until-first-whitespace'
import { normalizeAlphaNumericString } from '../utils/normalize-alphanumeric-string'

export type FormattedAlphanumericInputProps = Omit<
  TextInputBoxProps,
  'thousandSeparator' | 'inputRef'
> & {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const FormattedAlphanumericInput = forwardRef<
  HTMLInputElement,
  FormattedAlphanumericInputProps
>(({ id, value, onChange, onPaste, autoComplete = 'off', isComposing, style, ...props }, ref) => {
  const inputRef = useObjectRef(ref)
  const composingFormattedValueRef = useCompositionEndValueSync({
    isComposing,
    id,
    value,
    onChange,
    name: props.name,
  })

  const { format: _format, ...rest } = useNumericFormat({
    ...props,
    type: 'text',
    inputMode: 'text',
    defaultValue: props.defaultValue?.toString() ?? undefined,
  })

  const compositionValueFormatter = useCallback(
    // DO NOT CHANGE THIS METHOD: THIS PLACE IS THE SOURCE OF ANY POSSIBLE OS COMPOSITION BUG
    (val: string) => {
      if (!isComposing) {
        return getTextUntilFirstWhitespace(normalizeAlphaNumericString(val))
      }
      return val
    },
    [isComposing]
  )
  const handleValueChange = useCallback(
    ({ formattedValue: rawValue }: { formattedValue: string }) => {
      if (!isComposing) {
        composingFormattedValueRef.current = null
        onChange({
          target: {
            value: rawValue,
            id: id ?? '',
            name: props.name ?? '',
          },
        } as ChangeEvent<HTMLInputElement>)
      } else {
        composingFormattedValueRef.current = rawValue
      }
    },
    [id, isComposing, onChange, props.name, composingFormattedValueRef]
  )

  return (
    <NumberFormatBase
      {...props}
      {...rest}
      autoComplete={autoComplete}
      defaultValue={props.defaultValue?.toString() ?? undefined}
      id={id}
      onPaste={onPaste}
      style={style}
      format={compositionValueFormatter}
      onValueChange={handleValueChange}
      removeFormatting={(val) => val}
      type="text"
      value={value?.toString() ?? undefined}
      getInputRef={inputRef}
      valueIsNumericString={false}
      onFocus={(e) => reassignEventTargetToCurrentTarget(e, props.onFocus)}
      onBlur={(e) => reassignEventTargetToCurrentTarget(e, props.onBlur)}
    />
  )
})

FormattedAlphanumericInput.displayName = 'FormattedAlphanumericInput'
