type NormalizeNumericStringOptions = {
  ignoreMinusSign?: boolean
  ignoreDecimalFormatting?: boolean
  preserveInitialZeros?: boolean
}
export const normalizeNumericString = (val: string, options?: NormalizeNumericStringOptions) => {
  if (!val || !val.length) return ''
  const hasMinus = !options?.ignoreMinusSign && val.includes('-')
  let normalized = val.normalize('NFKC').replace(/[^0-9.]/g, '')

  if (options?.ignoreDecimalFormatting) {
    normalized = normalized.replace(/\./g, '')
  }
  if (!options?.preserveInitialZeros) {
    normalized = normalized.replace(/^0+(?=[0-9])/g, '')
  }
  return hasMinus ? `-${normalized}` : normalized
}
